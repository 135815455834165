/* global orderItemsSelectComponent */ 
class CustomOrderItemsSelectComponent extends orderItemsSelectComponent {

    getWatch() {
        const superWatch = super.getWatch();
        superWatch['display'] = function (newValue, oldValue) {
            if(newValue) {
                this.emitEvent("filter-menu-open","itemOrdered");
            }
        };
        return superWatch;
    }

    getData() {
        let superData = super.getData();
        return function () {
            return Object.assign(superData(),{
                display: false
            });
        };
    }

    mounted() {
        return async function () {
            this.eventMenuClose = this.eventMenuClose.bind(this);
            this.subscribeEvent("filter-menu-open",this.eventMenuClose);
        };
    }

    eventMenuClose(menuName) {
        if(menuName !== "itemOrdered" && this.display)
            this.display = false;
    }

    emitEvent(orderField) {
        this.orderSelected = orderField;
        this.$store.dispatch('reorderItems',this.orderSelected);
        this.$emit('reorder');
    }

    getMethods() {
        const superMethods = super.getMethods();
        superMethods.eventMenuClose = this.eventMenuClose;
        return superMethods;
    }

    getTemplate() {
        return `<div class="filter ">
                    <p class="col-auto filter-title" @click="display = !display">
                        {{tr("Order by")}}
                        <span v-show="display" class="right"></span>
                        <span v-show="!display" class="right"></span>
                    </p>
                    <div class="row filter-options" :class="{'visible-filter': display}">
                        <template v-if="options" v-for="orderField of options">
                            <p class="w-100">
                                <input name="order" type="radio" :id="orderField.fields.ItemField" @change="emitEvent(orderField.fields.internalId)" v-model="orderSelected" />
                                <label :for="orderField.fields.ItemField">{{tr(orderField.fields.ItemField)}} ({{orderField.fields.OrderDirection}})</label>
                            </p>
                        </template>
                    </div>
                </div>`;
    }
}

CustomOrderItemsSelectComponent.registerComponent();